export const NEWS = [{
    id: 3,
    post_date: '2021/11/18',
    title: '【11月25日(木)営業再開予定】店内リニューアルオープン！',
    content: `
平素より番風をご愛顧いだだきありがとうございます。

１０月末より、店内改装による休業をしておりましたが、
無事改装が完了し、再開の目処が立ちましたのでご連絡いたします。

営業再開日は以下の通りです。

【営業再開日】
11月25日（木） 17:00~

よりアップデートされた番風をぜひ楽しみにしてください！

従業員一同、皆様のお越しをお待ちしております。

番風オーナー`,
},{
    id: 1,
    post_date: '2021/10/14',
    title: '店内改装に伴う休業のお知らせ',
    content: `
平素より番風をご愛顧いだだきありがとうございます。

この度、店内改装のためしばらくの間休業させていただきます。

新装開店は11月半ばを予定しております。

お客様にとって、より心地良い空間をお届けできるよう準備してまいりますので、
ご理解のほどよろしくお願い申し上げます。

番風オーナー`,
},{
    id: 2,
    post_date: '2020/11/01',
    title: '番風WEBサイト開設しました。',
    content: `
本WEBページをご覧いただきありがとうございます。
新型コロナウイルスの影響で、「居酒屋は感染しないか不安…」「そもそもお店が営業しているかわからない…」
といったご意見をいただきましたので、「番風WEBサイト」を開設しました。

番風は2020年で20周年を迎えますが、より多くの皆様に、「楽しいひと時」をお届けできるよう、
従業員一同、努力してまいります。

これからも、「番風」をよろしくお願いいたします。

番風オーナー`,
}];
